import React from 'react'
import Header from './Components/Header';
import Footer from './Components/Footer';

function TermsPrivacy() {
    return (
        <>
            <Header />
            <section id="term-and-policy">
                <div className='container'>
                    <div className='my-5'>
                        <h1 className='text-start'>Privacy Policy</h1>
                        <p className='text-start'>By providing EVERRA REAL ESTATE your personal data, you agree and consent to EVERRA REAL
                            ESTATE  collecting, using, disclosing, sharing and otherwise processing said data for matching your requirements
                            or properties, processing future contracts in case of sale or lease, database profiling and other activities
                            reasonably related to EVERRA REAL ESTATE’s purposes as provided in its Articles of Incorporation and By-laws.
                            The data submitted may only be shared to affiliates and necessary third parties for legitimate purpose with the
                            assurance that EVERRA REAL ESTATE will take reasonable steps to ensure that those recipients only process the
                            data for the specific purpose for which it was collected and will protect the privacy of the personal data in
                            accordance with the provisions of RA 10173. Any enforcement of data privacy rights pursuant to RA 10173 may be
                            coursed through the Data Protection Officer (DPO) of EVERRA REAL ESTATE.
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default TermsPrivacy